<template>
  <div>
    <div class="data" @click="handleChild">
      <ApexCharts :modal-active="modalActive" />
    </div>
    <div @click="handleChild" class="menu menu__right">
      <b-button @click="modalActive = !modalActive">
        <span class="mdi mdi-cog-outline"></span>
        Settings
      </b-button>
    </div>
    <div class="menu menu__left">
      <b-button v-if="!modalActive">
        <span class="mdi mdi-keyboard-backspace"></span>
      </b-button>
    </div>
  </div>
</template>

<script>
import ApexCharts from './ApexCharts.vue'
export default {
  name: 'Data',
  // props: {
  //   total: {
  //     type: Number,
  //     required: true,
  //     default() {
  //       return 0
  //     },
  //   },
  //   threshold: {
  //     type: Number,
  //     required: true,
  //     default() {
  //       return 0
  //     },
  //   },
  // },
  data () {
    return {
      modalActive: false,
    }
  },
  components: {
    ApexCharts
  },
  methods: {
    handleChild(e) {
      e.stopPropagation();
    },
  }
}
</script>
<style lang="scss" global>
@import url('//cdn.jsdelivr.net/npm/@mdi/font@6.5.95/css/materialdesignicons.min.css');

.data {
  margin: 0 auto;
  width: 740px;

  p {
    min-width: 80px;
  }
}

.menu {
  position: absolute;
  width: 50%;

  &__left {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &__right {
    top: 0;
    right: 0;
    text-align: right;
  }

  .button {
    margin: 1rem;
  }

  .mdi-keyboard-backspace {
    font-size: 2.5rem;
  }
}
</style>
