<template>
  <div class="clock">
    <svg xmlns="http://www.w3.org/2000/svg" width="740" height="740" viewBox="-370 -370 740 740">
      <circle r="370" fill="#EFEFEF"/>
      <circle r="290" class="urtavla"/>
      <circle
        v-for="(hour, indexProgress) in selectedHours"
        :key="indexProgress"
        :r="hour.value * 3.6"
        cy="-250"
        :fill="getColor(hour.value)"
        :opacity="getFade(indexProgress)"
        :transform="'rotate(' + getDegrees(hour.time) + ')'"
      />
      <path
        class="visare"
        d="M10.5-16.57c-0.05-0.03-0.11-0.07-0.16-0.1c-3.4-2.1-5.55-5.73-5.55-9.72V-99.7h-9.56v73.3c0,4-2.16,7.63-5.57,9.73
        c-3.13,1.94-5.75,4.78-7.45,8.41c-4.08,8.75-1.05,19.38,7.03,24.65c10.15,6.62,23.72,2.63,28.68-8.42
        C21.87-0.83,18.66-11.41,10.5-16.57z"
        fill="#303031"
        :transform="'rotate(' + getDegrees(timeNow) + ') scale(1.8)'"
      />
      <line class="line" x1="0" y1="-370" x2="0" y2="-320" :transform="'rotate(0)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-340" :transform="'rotate(30)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-340" :transform="'rotate(60)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-320" :transform="'rotate(90)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-340" :transform="'rotate(120)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-340" :transform="'rotate(150)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-320" :transform="'rotate(180)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-340" :transform="'rotate(210)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-340" :transform="'rotate(240)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-320" :transform="'rotate(270)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-340" :transform="'rotate(300)'" />
      <line class="line" x1="0" y1="-370" x2="0" y2="-340" :transform="'rotate(330)'" />
    </svg>
  </div>
</template>
<script>
import { format, parse, roundToNearestMinutes } from 'date-fns'

export default {
  props: {
    background: {
      type: String,
      required: true,
      default() {
        return ''
      },
    },
    timeNow: {
      type: Date,
      required: false,
      default() {
        return ''
      },
    },
    progressData: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      count: 0
    }
  },
  methods: {
    getFade(index) {
      let opacity = 1;
      switch(index) {
        case 20:
          opacity = 0.8;
          break;
        case 21:
          opacity = 0.6;
          break;
        case 22:
          opacity = 0.25;
          break;
        case 23:
          opacity = 0.1;
          break;
        default:
          opacity = 1;
        }
      return opacity
    },
    getColor(value) {
      let color = '#1D4253';
      
      switch (true) {
        case (value < 2):
          color = '#C8E3D6'
          break;
        case (value < 3):
          color = '#9EBBB5'
          break;
        case (value < 4):
          color = '#739395'
          break;
        case (value < 5):
          color = '#4A6A75'
          break;
        case (value < 6):
          color = '#315769'
          break;
      }
      return color
    },
    getDegrees(time){
      if (typeof time === 'string') {
        time = parse(time, 'HH:mm', new Date())
      }
      
      const hour = format((time), 'HH', {})
      const hourDegrees = hour * 30;
      const minute =  format((time), 'mm', {})
      const minuteDegrees = minute * 0.5;
      return hourDegrees + minuteDegrees;
    }
  },
  computed: {
    // progressData() {
    //   return this.progressData;
    // },
    selectedHours(){
      let minutes = '00'
      if (this.currentMinuteNumber >= 30) {
          minutes = '30'
      }
      const currentTime = this.currentHourNumber + ":" + minutes;
      const index = this.progressData.findIndex(function(item) {
        return item.time === currentTime
      });

      const beforeNowArray = (this.progressData).slice(0,index)
      const fromNowArray = (this.progressData).slice(index,48)          
      const combinedArray = fromNowArray.concat(beforeNowArray);
    
      return combinedArray.splice(0,24)
    },
    currentHourNumber() {
      return format((this.timeNow), 'HH', {});
    },
    currentMinuteNumber() {
      return format((this.timeNow), 'mm', {})
    },
    currentTimeStringRounded() {
      // Round 10 July 2014 12:07:30 to nearest quarter hour:
      const roundedTime = roundToNearestMinutes(this.timeNow, { nearestTo: 30 })
      return format((roundedTime), 'HH:mm', {});
    },
  }
}
</script>
<style lang="scss" global>
.urtavla {
  fill: white;
}

.line {
  stroke: white;
  stroke-width: 12;
}
</style>
