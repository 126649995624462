import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

const url = window.location.href;
const data = require('./static/data.json');
const progress = require('./static/progress.json');
const progressFriday = require('./static/progress_friday.json');
const progressWeekend = require('./static/progress_weekend.json');

export default new Vuex.Store({
  state: {
    threshold: 6,
    hsbData: data,
    progress: progress,
    progressFriday: progressFriday,
    progressWeekend: progressWeekend,
    updated: false,
  },
  actions: {
    async updateApi({ commit }, data) {
      try {
        // await axios.get('//norm-kreativ.boid.se/api.php?set', {
        await axios.get(`${url}api.php?set`, {
          params: {
            threshold: data.threshold,
            total: data.total,
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
          },
        });
        commit('IS_UPDATED', true);
      } catch (error) {
        console.error('error', error);
        commit('IS_UPDATED', false);
      }
    },
  },
  mutations: {
    IS_UPDATED: (state, data) => {
      state.updated = data;
    },
  }
})
