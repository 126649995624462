<template>
  <div id="app" @click="toggleScreens = !toggleScreens">
    <Transition name="fade">
      <Clock :background="background" :timeNow="timeNow" :progress-data="progressValue" v-if="!toggleScreens" />
      <Data v-if="toggleScreens" />
    </Transition>
  </div>
</template>
<script>
import Data from './components/Data.vue'
import Clock from './components/Clock.vue'

export default {
  name: 'App',
  components: {
    Data,
    Clock,
  },
  data () {
    return {
      background: '#eeeeee',
      timeNow: null,
      toggleScreens: false,
      progressValue: [],
    }
  },
  computed: {
    progress() {
      return this.$store.state.progress;
    },
    progressFriday() {
      return this.$store.state.progressFriday;
    },
    progressWeekend() {
      return this.$store.state.progressWeekend;
    },
    threshold() {
      return this.$store.state.threshold;
    },
    total() {
      let totalValue = 0;
      this.$store.state.hsbData.forEach(data => {
        totalValue += data.progress
      });
      return parseFloat(totalValue.toFixed(2));
    },
  },
  created() {
    this.getCurrentTime()

    setInterval(() => {
      this.getCurrentTime()
      this.getProgress()
    }, 1000)
  },
  methods: {
    getProgress() {
      const today = new Date();
      const weekday = new Array(6, 0, 1, 2, 3, 4, 5);

      if (weekday[today.getDay()] >= 0 && weekday[today.getDay()] <= 3) {
        return this.progressValue = this.progress
      } else if (weekday[today.getDay()] === 4) {
        return this.progressValue = this.progressFriday
      } else {
        return this.progressValue = this.progressWeekend
      }
    },
    getCurrentTime() {
      this.timeNow = new Date();
    },
  },
  watch: {
    total() {
      this.$store.dispatch('updateApi', { threshold: this.threshold, total: this.total });
    }
  }
}
</script>
<style>
body {
  background-color: antiquewhite;
  height: 100vh;
  width: 100%;
  font-size: 16px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #2c3e50;
  width: 1024px;
  height: 768px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.clock,
.data {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-background {
  background-color: #fff !important;
}
</style>
