<template>
  <div id="chart" class="chart">
    <apexchart ref="donut" type="donut" :options="chartOptions" :series="series" @dataPointSelection="dataPointSelectionHandler"></apexchart>
    <div class="chart__content">
      <div v-if="showLastSelected && showLastSelected.progress !== 0">
        <p class="labelText">{{ showLastSelected.name }}:</p>
        <p>{{ showLastSelected.progress }} kW</p>
      </div>

      <div v-else>
        <p style="text-align: center;">
          Tröskel: {{ threshold }} kW
          <span
            v-if="threshold < total"
            class="mdi mdi-alert-circle-outline"
          ></span>
        </p>
        <p style="text-align: center;">Total energianvändning: {{ total }} kW</p>
      </div>
    </div>

    <b-modal
      v-model="modalActive"
      full-screen
    >
      <div class="card">
        <div class="card-content">
          <h2 class="settings-title">Settings</h2>
          <b-button @click="updateApi()">Update API</b-button>
        </div>
        <div v-for="(data, indx) in rawData" :key="indx">
          <div class="card-content">
            <b-field :label="`${data.name}: ${data.progress} kW`">
              <b-slider
                v-model="data.progress"
                size="is-medium"
                type="is-dark"
                :max="6"
                :step="0.1"
                tooltip-type="is-dark"
              >
              </b-slider>
            </b-field>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import { xor } from 'lodash';
Vue.use(VueApexCharts)

export default {
  name: 'Apex',
  props: {
    modalActive: {
      type: Boolean,
      required: true,
      default() {
        return false
      },
    }
  },
  data () {
    return {
      selected: [],
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
        },
        labels: [],
        colors: [],
        legend: {
          show: false,
          // position: 'bottom',
          // onItemClick: {
          //   toggleDataSeries: true,
          // },
          // onItemHover: {
          //   highlightDataSeries: false,
          // },
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
          },
          dropShadow: {
            enabled: false,
          },
          /* eslint-disable */
          formatter: (value, { seriesIndex }) => {
            return this.hsbData[seriesIndex].name
          }
        },
      },
    }
  },
  components: {
    apexchart: VueApexCharts,
  },
  created() {
    this.getHsbData();
  },
  computed: {
    threshold() {
      return this.$store.state.threshold;
    },
    total() {
      let totalValue = 0;
      this.$store.state.hsbData.forEach(data => {
        totalValue += data.progress
      });
      return parseFloat(totalValue.toFixed(2));
    },
    showLastSelected() {
      return this.selected[0]
    },
    hsbData() {
      return this.$store.state.hsbData.filter(data => data.progress !== 0)
    },
    rawData() {
      return this.$store.state.hsbData;
    }
  },
  methods: {
    dataPointSelectionHandler(event, chartContext, config) {
      if (this.selected.length > 0) {
        if (this.selected.includes(this.hsbData[config.dataPointIndex])) {
          this.selected = xor(this.selected, [this.hsbData[config.dataPointIndex]])
        } else {
          this.selected = []
          this.selected = xor(this.selected, [this.hsbData[config.dataPointIndex]])
        }
      } else {
        this.selected = xor(this.selected, [this.hsbData[config.dataPointIndex]])
      }
    },
    getHsbData() {
      this.chartOptions.colors = this.hsbData.map(data => data.color)
      this.chartOptions.labels = this.hsbData.map(data => data.name)
    
      let total = 0;
      this.$store.state.hsbData.forEach(data => {
        total += data.progress
      });

      this.series = this.hsbData.map(data => Math.round(data.progress / total * 100))
    },
    updateApi() {
      this.$store.dispatch('updateApi', { threshold: this.threshold, total: this.total });
    }
  },
  watch: {
    hsbData(val) {
      let total = 0;
      val.forEach(data => {
        total += data.progress
      });

      const newColors = val.map(data => data.color)
      const newLabels = val.map(data => data.name)
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          colors: newColors,
          labels: newLabels,
        }
      };

      this.series = val.map(data => Math.round(data.progress / total * 100));

      this.$refs.donut.updateSeries(this.series, true);
      this.$refs.donut.updateOptions(this.chartOptions, false, true);
    }
  }
}
</script>
<style lang="scss" scoped>
.chart {
  position: relative;

  &__content {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    text-align: center;

    p {
      font-size: 1.2rem;
      margin: 0.5rem 0;
    }

    .labelText {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
}

.modal {
  .card-content {
    width: 50%;
    margin: 0 auto;
  }

  .settings-title {
    font-weight: bold;
    font-size: 2rem;
  }
}
</style>